export default class PermissaoService {
  constructor(http) {
    this._http = http
  }

  async getPermissoes() {
    const { data } = await this._http.get('/api/permissoes/list')
    return data
  }

  async savePermissao(permissao) {
    if (permissao.id) {
      return await this._http.put('/api/permissoes', permissao)
    } else {
      return await this._http.post('/api/permissoes', permissao)
    }
  }

  async getPermissao(id) {
    const { data } = await this._http.get(`/api/permissoes/${id}`)
    return data
  }
}
