<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />

        <Toolbar class="mb-4">
          <template #start>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="permissaoNew" />
          </template>
        </Toolbar>

        <DataTable
          :value="permissoes"
          striped-rows
          :paginator="true"
          :rows="10"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[5, 10, 25]"
          current-page-report-template="Showing {first} to {last} of {totalRecords} permissões"
          responsive-layout="scroll">
          <template #header>
            <div class="table-header">Permissões</div>
          </template>
          <Column field="id" header="ID"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column header="Ativo">
            <template #body="slotProps">
              <span>{{ slotProps.data.ativo ? 'Em uso' : 'Fora de Uso' }}</span>
            </template>
          </Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editPermissao(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import PermissaoService from '@/service/PermissaoService.js'

export default {
  data() {
    return {
      permissoes: null,
      permissao: {},
    }
  },

  permissaoService: null,

  created() {
    this.permissaoService = new PermissaoService(this.$http)
  },

  mounted() {
    this.permissaoService.getPermissoes().then((res) => (this.permissoes = res))
  },

  methods: {
    permissaoNew() {
      this.$router.push({ name: 'permissao-new' })
    },

    editPermissao(data) {
      this.$router.push({ name: 'permissao-edit', params: { id: data.id } })
    },
  },
}
</script>

<style></style>
